export async function registerEvent(appName, campaign, share, report) {
  await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('get', `https://event.memix.com/webEvent?appName=${appName}&campaign=${campaign}&share=${share}&osName=${report.os.name}&osVersion=${report.os.version}&osModel=${report.os.model}&lang=${report.lang}`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onload = () => resolve(xhr.responseText);
    xhr.onerror = (e) => reject(e);
    xhr.send();
  });
}
