import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { MainPage } from '~pages/MainPage';

export default () => {
  return (
    <Routing />
  );
};

const Routing = () => {
  return (
    <Routes>
      <Route path='/u/:shareLinkId' element={<MainPage />} />
      <Route path='/c/:campaignId' element={<MainPage />} />
      <Route path='*' element={<MainPage />} />
    </Routes>
  );
};
