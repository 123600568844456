import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { browserReportSync } from '~util/browser-report';
import { registerEvent } from '~util/register-event';

export const MainPage = () => {
  const {
    shareLinkId,
    campaignId
  } = useParams();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const report = browserReportSync();
      // console.log(report);

      const isAndroid = report.os.name === 'Android';
      const isIOS = report.os.name === 'iOS';

      const queryParams = new URLSearchParams(location.search);
      const campaign = queryParams.get('c') ?? campaignId ?? null;
      const share = queryParams.get('s') ?? shareLinkId ?? null;

      let iOSAppCampaign = campaign;
      if (share && share?.length > 0 && !campaign) {
        iOSAppCampaign = 'share-link';
      }

      const appName = 'memix';
      const iOSAppUrl = `https://apps.apple.com/app/apple-store/id6443475929?pt=120139705&ct=${iOSAppCampaign}&mt=8`;
      const androidAppUrl = 'https://play.google.com/store/apps/details?id=com.irl.memix.android';

      // console.log(`shareLinkId: ${shareLinkId}, campaign: ${campaign}, share: ${share}, iOSAppCampaign: ${iOSAppCampaign}`);

      try {
        await registerEvent(appName, campaign, share, report);
      } catch (e) {
        console.error(e);
        console.log('event registration failed');
      }

      // console.log('redirecting to app store');

      if (isAndroid) {
        window.location.assign(androidAppUrl);
      } else {
        window.location.assign(iOSAppUrl);
      }
    })();
  }, [shareLinkId, campaignId, location]);

  return (
    <div />
  );
};
